export const winners = [
  {
    name: "cauanzin",
    org: "LOUD",
    twitter: "https://twitter.com/cauanzinvlr",
    twitch: "",
    instagram: "http://instagram.com/cauanzinpd",
    categoria: "MELHOR_INICIADOR",
    jogo: "VALORANT",
  },
  {
    name: "tuyz",
    org: "LOUD",
    twitter: "https://twitter.com/loud_tuyz",
    twitch: "https://www.twitch.tv/tuyzin",
    instagram: "https://www.instagram.com/loud_tuyz1",
    categoria: "MELHOR_CONTROLADOR",
    jogo: "VALORANT",
  },
  {
    name: "aspas",
    org: "Leviatán",
    twitter: "https://twitter.com/aspaszin",
    twitch: "https://www.twitch.tv/aspaszin",
    instagram: "https://www.instagram.com/aspaszin/",
    categoria: "MELHOR_DUELISTA",
    jogo: "VALORANT",
  },
  {
    name: "Less",
    org: "LOUD",
    twitter: "https://twitter.com/loud_less",
    twitch: "https://www.twitch.tv/lessvlr",
    instagram: "https://www.instagram.com/lessvlr/",
    categoria: "MELHOR_SENTINELA",
    jogo: "VALORANT",
  },
  {
    name: "Saadhak",
    org: "LOUD",
    twitter: "https://twitter.com/loud_saadhak",
    twitch: "https://www.twitch.tv/saadhak",
    instagram: "https://www.instagram.com/saadhak.gg/",
    categoria: "MELHOR_CAPITAO",
    jogo: "VALORANT",
  },
  {
    name: "Frod",
    org: "MIBR",
    twitter: "https://twitter.com/OfficialfRoD",
    twitch: "https://www.twitch.tv/frod_tv",
    instagram: "https://www.instagram.com/frod.gg",
    categoria: "MELHOR_COACH",
    jogo: "VALORANT",
  },
  {
    name: "LOUD",
    org: "",
    twitter: "https://twitter.com/LOUDgg",
    twitch: "",
    instagram: "https://www.instagram.com/loudgg",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "VALORANT",
  },
  {
    name: "Bizerra",
    org: "Liquid",
    twitter: "https://twitter.com/bizerravlr",
    twitch: "",
    instagram: "https://www.instagram.com/bizerravlr",
    categoria: "JOGADORA_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Less",
    org: "LOUD",
    twitter: "https://twitter.com/loud_less",
    twitch: "https://www.twitch.tv/lessvlr",
    instagram: "https://www.instagram.com/lessvlr/",
    categoria: "JOGADOR_DO_ANO",
    jogo: "VALORANT",
  },
  {
    name: "Otsuka",
    org: "FURIA",
    twitter: "https://twitter.com/otsukafps",
    twitch: "https://www.twitch.tv/otsukaXD",
    instagram: "https://www.instagram.com/otsukaxd/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "VALORANT",
  },
];
