import { combineReducers } from "redux";
import {
  VOTE_CSGO_ASSAULT,
  VOTE_CSGO_AWP,
  VOTE_CSGO_CAPTAIN,
  VOTE_CSGO_COACH,
  VOTE_CSGO_FEMALE_PLAYER,
  VOTE_CSGO_FOREIGN_PLAYER,
  VOTE_CSGO_INFLUENCER,
  VOTE_CSGO_MALE_PLAYER,
  VOTE_CSGO_NEW_PLAYER,
  VOTE_CSGO_ORG,
  VOTE_CSGO_LEAGUE_PLAYER,
  VOTE_CSGO_LEAGUE_ORG,
  VOTE_CSGO_YOUNG_PLAYER,
  VOTE_VALORANT_CAPTAIN,
  VOTE_VALORANT_COACH,
  VOTE_VALORANT_CONTROLLER,
  VOTE_VALORANT_DUELIST,
  VOTE_VALORANT_FEMALE_PLAYER,
  VOTE_VALORANT_INFLUENCER,
  VOTE_VALORANT_INITIATOR,
  VOTE_VALORANT_MALE_PLAYER,
  VOTE_VALORANT_ORG,
  VOTE_VALORANT_SENTINEL,
  SELECT_GAME,
  CHOOSE,
  HAS_VOTED_FOR_VALORANT,
  HAS_VOTED_FOR_CS,
  SET_ERROR_MESSAGE,
} from "../constants/action-types";

// TODO: [CONSTANTS] Update with expired and finished status
const app = {
  chosen: "",
  game: "",
  hasVotedForValorant: false,
  hasVotedForCS: false,
  errorMessage: "",
  expired: true,
  finished: true,
};

const csgo = {
  assault: "",
  awp: "",
  captain: "",
  coach: "",
  organization: "",
  newPlayer: "",
  foreignPlayer: "",
  malePlayer: "",
  femalePlayer: "",
  influencer: "",
  leaguePlayer: "",
  leagueOrg: "",
  youngPlayer: "",
};

const valorant = {
  initiator: "",
  controller: "",
  duelist: "",
  sentinel: "",
  captain: "",
  coach: "",
  organization: "",
  malePlayer: "",
  femalePlayer: "",
  influencer: "",
};

function appReducer(state = app, action) {
  if (action.type === CHOOSE) {
    return { ...state, chosen: action.payload };
  }

  if (action.type === SELECT_GAME) {
    return { ...state, game: action.payload };
  }

  if (action.type === HAS_VOTED_FOR_CS) {
    return { ...state, hasVotedForCS: action.payload };
  }

  if (action.type === HAS_VOTED_FOR_VALORANT) {
    return { ...state, hasVotedForValorant: action.payload };
  }

  if (action.type === SET_ERROR_MESSAGE) {
    return { ...state, errorMessage: action.payload };
  }

  return state;
}

function csgoReducer(state = csgo, action) {
  if (action.type === VOTE_CSGO_ASSAULT) {
    return { ...state, assault: action.payload };
  }

  if (action.type === VOTE_CSGO_AWP) {
    return { ...state, awp: action.payload };
  }

  if (action.type === VOTE_CSGO_CAPTAIN) {
    return { ...state, captain: action.payload };
  }

  if (action.type === VOTE_CSGO_COACH) {
    return { ...state, coach: action.payload };
  }

  if (action.type === VOTE_CSGO_ORG) {
    return { ...state, organization: action.payload };
  }

  if (action.type === VOTE_CSGO_NEW_PLAYER) {
    return { ...state, newPlayer: action.payload };
  }

  if (action.type === VOTE_CSGO_FOREIGN_PLAYER) {
    return { ...state, foreignPlayer: action.payload };
  }

  if (action.type === VOTE_CSGO_MALE_PLAYER) {
    return { ...state, malePlayer: action.payload };
  }

  if (action.type === VOTE_CSGO_FEMALE_PLAYER) {
    return { ...state, femalePlayer: action.payload };
  }

  if (action.type === VOTE_CSGO_INFLUENCER) {
    return { ...state, influencer: action.payload };
  }

  if (action.type === VOTE_CSGO_LEAGUE_PLAYER) {
    return { ...state, leaguePlayer: action.payload };
  }

  if (action.type === VOTE_CSGO_LEAGUE_ORG) {
    return { ...state, leagueOrg: action.payload };
  }

  if (action.type === VOTE_CSGO_YOUNG_PLAYER) {
    return { ...state, youngPlayer: action.payload };
  }

  return state;
}

function valorantReducer(state = valorant, action) {
  if (action.type === VOTE_VALORANT_INITIATOR) {
    return { ...state, initiator: action.payload };
  }

  if (action.type === VOTE_VALORANT_CONTROLLER) {
    return { ...state, controller: action.payload };
  }

  if (action.type === VOTE_VALORANT_DUELIST) {
    return { ...state, duelist: action.payload };
  }

  if (action.type === VOTE_VALORANT_SENTINEL) {
    return { ...state, sentinel: action.payload };
  }

  if (action.type === VOTE_VALORANT_CAPTAIN) {
    return { ...state, captain: action.payload };
  }

  if (action.type === VOTE_VALORANT_COACH) {
    return { ...state, coach: action.payload };
  }

  if (action.type === VOTE_VALORANT_ORG) {
    return { ...state, organization: action.payload };
  }

  if (action.type === VOTE_VALORANT_MALE_PLAYER) {
    return { ...state, malePlayer: action.payload };
  }

  if (action.type === VOTE_VALORANT_FEMALE_PLAYER) {
    return { ...state, femalePlayer: action.payload };
  }

  if (action.type === VOTE_VALORANT_INFLUENCER) {
    return { ...state, influencer: action.payload };
  }

  return state;
}

const rootReducer = combineReducers({
  app: appReducer,
  csgo: csgoReducer,
  valorant: valorantReducer,
});

export default rootReducer;
