export const winners = [
  {
    name: "Lucaozy",
    org: "Fluxo",
    twitter: "https://twitter.com/Lucaozy",
    twitch: "https://www.twitch.tv/lucaozyfps",
    instagram: "https://www.instagram.com/lucaozy/",
    categoria: "MELHOR_ASSAULT",
    jogo: "CSGO",
  },
  {
    name: "HEN1",
    org: "Imperial",
    twitter: "https://twitter.com/Hen1",
    twitch: "https://www.twitch.tv/henriquehen1",
    instagram: "https://www.instagram.com/henriquehen1",
    categoria: "MELHOR_AWP",
    jogo: "CSGO",
  },
  {
    name: "biguzera",
    org: "paiN",
    twitter: "https://twitter.com/biguzera1",
    twitch: "https://www.twitch.tv/biguzera1",
    instagram: "https://www.instagram.com/biguzera1",
    categoria: "MELHOR_CAPITAO",
    jogo: "CSGO",
  },
  {
    name: "zakk",
    org: "Imperial",
    twitter: "https://twitter.com/zakkfps",
    twitch: "",
    instagram: "https://www.instagram.com/rafaxfernandes",
    categoria: "MELHOR_COACH",
    jogo: "CSGO",
  },
  {
    name: "FURIA",
    org: "",
    twitter: "https://twitter.com/FURIA",
    twitch: "https://www.twitch.tv/furiatv",
    instagram: "https://www.instagram.com/furiagg/",
    categoria: "MELHOR_ORGANIZACAO",
    jogo: "CSGO",
  },
  {
    name: "noway",
    org: "Imperial",
    twitter: "https://twitter.com/nowayzao",
    twitch: "https://www.twitch.tv/nowayzao",
    instagram: "https://www.instagram.com/nowayzao",
    categoria: "REVELACAO",
    jogo: "CSGO",
  },
  {
    name: "dgt",
    org: "9z Team",
    twitter: "https://twitter.com/dgtcsgo",
    twitch: "https://www.twitch.tv/dgtcsgo",
    instagram: "https://www.instagram.com/frandgt_",
    categoria: "ESTRANGEIRO_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "GaBi",
    org: "FURIA fe",
    twitter: "https://twitter.com/gabiifps",
    twitch: "https://www.twitch.tv/gabiifps",
    instagram: "https://www.instagram.com/gabicsgo",
    categoria: "JOGADORA_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "lucaozy",
    org: "Fluxo",
    twitter: "https://twitter.com/Lucaozy",
    twitch: "https://www.twitch.tv/lucaozyfps",
    instagram: "https://www.instagram.com/lucaozy/",
    categoria: "JOGADOR_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "Gaules",
    org: "Tribo",
    twitter: "https://twitter.com/Gaules",
    twitch: "https://www.twitch.tv/gaules",
    instagram: "https://www.instagram.com/gaules/",
    categoria: "PERSONALIDADE_DO_ANO",
    jogo: "CSGO",
  },
  {
    name: "saadzin",
    org: "W7M",
    twitter: "https://twitter.com/saadzin_fps",
    twitch: "",
    instagram: "",
    categoria: "JOGADOR_LIGAS",
    jogo: "CSGO",
  },
  {
    name: "MIBR Academy",
    org: "MIBR Academy",
    twitter: "https://twitter.com/MIBR",
    twitch: "https://www.twitch.tv/mibrtv",
    instagram: "https://www.instagram.com/mibrteam/",
    categoria: "ORGANIZACAO_LIGAS",
    jogo: "CSGO",
    avatar: "mibr-academy",
  },
  {
    name: "poppins",
    org: "Fluxo Demons",
    twitter: "https://twitter.com/poppinscs",
    twitch: "",
    instagram: "https://www.instagram.com/poppinscs",
    categoria: "JOVEM_DO_ANO",
    jogo: "CSGO",
  },
];
